.container{
  max-width:111.2rem;
  margin:0 auto;
}
.header{
  padding:3.2rem 0;
}
.uqbarlogo{
  max-width:20rem;
  display:inline-block;
}

.discordlogo {
  width: 2em;
  vertical-align: middle;
  margin-left: 0.2em;
}

.navbar{
  float:right;
  text-align:right;
  font-weight:600;
}
.navbar ul{
  list-style:none;
}
.navbar ul li {
  margin-left:0.8rem;
  display:inline-block;
}
.navbar ul li a {
  padding:1rem;
  color:#6B6A81;
}
.navbar ul li a:hover{
  color:#0082FF;
}

@media screen and (max-width: 800px) {
  .header{
    padding:2.4rem 0;
    margin-bottom: 5rem;
  }
  .container{
    padding:0 2.4rem;
  }
  .navbar{
    float:right;
    width: 100%;
  }
  .navbar ul{
    margin: auto;
    padding: inherit;
    text-align: center;
  }
  .navbar ul li {
    margin-left:0rem;
    display:inline-block;
  }
  .navbar ul li a {
    padding:0.4rem;
    color:#6B6A81;
  }
  .uqbarlogo{
    width: 100%;
    text-align:center;
    display:block;
    margin:0 auto;
  }
}
.app{
  width:100%;
  font-size:1.6rem;
  line-height:2.4rem;
}

.container {
  max-width: 111.2rem;
  margin: 0 auto;
}

.projectLogo {
  width: 100%;
  text-align: center;
  align-items: center;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.header {
  margin-top: 5rem;
}

a{
  color:#0082FF;
  text-decoration:none;
  transition: all 0.3s ease-in-out;
}
a:hover{
 color:#6B6A81
}
h1, h2, h3, h4{
  font-family: 'Prompt', sans-serif;
  padding-left: 2rem;
}
.color-muted{
  color:#6B6A81;
}

strong{
  font-weight:600;
}

img{
  max-width:100%;
}

@media screen and (max-width: 800px) {
  h2 {
    font-size: 2.4rem;
    line-height: 3.2rem;
    text-align: center;
  }
  .container{
    padding:0 2.4rem;
  }
}
.contacto {
  padding:8rem 0;
}
.content {
  display: flex;
}
.contentLeft {
  max-width: 47.2rem;
}
.contentRight {
  margin-left: 4rem;
  flex-grow: 1;
  text-align:right;
}
.destacado {
  font-size: 2rem;
  line-height: 3.2rem;
  color: #6b6a81;
}
ul.modosContacto {
  background: #ffffff;
  border: 1px solid #e3e2ef;
  box-shadow: 0 4px 10px -4px rgba(70, 70, 130, 0.3);
  border-radius: 8px;
  max-width:31.2rem;
  margin: 2.4rem 0;
  padding: 1.2rem 2.4rem;
  list-style: none;
  font-weight:600;
  transition: all 0.3s ease-in-out;
}
ul.modosContacto:hover{
  box-shadow: 0 10px 20px -10px rgba(70, 70, 130, 0.5);
}
ul.modosContacto li {
  border-bottom: 1px solid #e3e2ef;
  padding: 1rem 0;
  display: block;
  padding-left: 4rem;
  background-repeat: no-repeat;
  background-position: 0 50%;
}
ul.modosContacto li:last-child {
  border-bottom: none;
}
.contacto01 {
  background-image: url(red-facebook.svg);
}
.contacto02 {
  background-image: url(red-instagram.svg);
}
.contacto03 {
  background-image: url(red-twitter.svg);
}
.contacto04 {
  background-image: url(red-youtube.svg);
}
.contacto05 {
  background-image: url(red-email.svg);
}

@media screen and (max-width: 800px) {
  .contacto {
    padding: 2.4rem 0;
  }
  .container{
    padding:0 2.4rem;
  }
  .destacado {
    font-size: 1.8rem;
    line-height: 2.4rem;
  }
  .content{
    flex-wrap:wrap;
  }
  .contentLeft{
    flex-basis:100%;
    flex-shrink: 0;
    order:2;
  }
  .contentRight{
    flex-basis:100%;
    order:1;
    flex-shrink: 0;
    margin-left:0;
  }
  ul.modosContacto {
    max-width:100%;
  }
}
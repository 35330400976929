.lang-buttons {
    float:right;
    text-align:right;
}

.modal {
    position: absolute;
    width: 90%;
    height: 90%;
    background-color: white;
    padding: 3rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: '2px solid';
    box-sizing: border-box;
    box-shadow: 0 4px 10px -4px rgba(70, 70, 130, 0.3);
    border-radius: 8px;
    overflow-y: auto;
}

.modal h2 {
    display: flex;
    font-size: 2.5rem;
}

.modal h3 {
    font-size: 2rem;
}

.modal p {
    font-size: 1.5rem;
}

.modal span {
    font-size: 1.3rem;
}

.modal h2 img {
    position: absolute;
    height: 7rem;
    right: 0;
    transform: translate(-20%, -30%);
}
.donaciones {
  z-index: 1;
  background-color: #f7f7fa;
  margin-top: -20rem;
  padding-top: 24rem;
  padding-bottom: 8rem;
}

h2 {
  font-size: 4rem;
  line-height: 4.8rem;
}
.content {
  display: flex;
}
.contentLeft {
  max-width: 60.8rem;
  text-align: justify;
}
.contentRight {
  margin-left: 4rem;
  flex-grow: 1;
  text-align: justify;
}
.destacado {
  font-size: 2rem;
  line-height: 3.2rem;
  color: #6b6a81;
  text-align: justify;
}
h3{
  margin-bottom:0;
}
.linksMercadoPago, .cuentaBancaria {
  text-align: center;
  display: inline-block;
  margin-bottom:2.4rem;
  width:100%;
}
.linksMercadoPago a {
  margin-right: 0.8rem;
  margin-left: 0.8rem;
  background: #0082ff;
  border-radius: 0.8rem;
  padding: 1.6rem 2.4rem;
  font-weight: 600;
  color: #fff;
  display: inline-block;
  top:0;
  position:relative;
  box-shadow: 0 4px 10px -4px rgba(70, 70, 130, 0.3);
}
.linksMercadoPago a:hover {
  background: #253E8A;
  box-shadow: 0 10px 20px -10px rgba(70, 70, 130, 0.5);
  top:-4px;
}
.logoMercadoPago {
  text-align: center;
  display: inline-block;
  width: 100%;
  margin-top: 2.4rem;
}
.dataBanco {
  background-color: lightgray;
  font-family: monospace;
}

@media screen and (max-width: 800px) {
  .donaciones {
    padding-bottom: 2.4rem;
  }
  .container{
    padding:0 2.4rem;
  }
  .destacado {
    font-size: 1.8rem;
    line-height: 2.4rem;
  }
  .content{
    flex-wrap:wrap;
  }
  .contentLeft{
    flex-basis:100%;
    flex-shrink: 0;
    order:2;
  }
  .contentRight{
    flex-basis:100%;
    order:1;
    flex-shrink: 0;
    margin-left:0;
  }
  .linksMercadoPago a {
    width:100%;
    margin-right: 0;
  margin-top:0.8rem;
  }
  .logoMercadoPago {
    text-align:center;
  }
}
.equipo {
  background-color: #f7f7fa;
}
.equipoListado {
  display: grid;
  grid-template-columns: repeat(4, 25%);
  grid-gap: 1.6rem;
  grid-auto-rows: min-content max-content auto;
  grid-auto-flow: dense;
  align-content: space-around;	
}
/* Inactive member */
.member {
  border: 1px solid #e3e2ef;
  background-color: #fff;
  box-sizing: border-box;
  box-shadow: 0 4px 10px -4px rgba(70, 70, 130, 0.3);
  border-radius: 8px;
  padding: 1.6rem;
  transition: all 0.5s ease-in;
  position: relative;
  top: 0;
  grid-column-end: span 1;
  grid-row-end: span 1;
}
.member:hover {
  box-shadow: 0 10px 20px -10px rgba(70, 70, 130, 0.5);
  cursor: pointer;
  top: -4px;
}
.photo {
  width: 4.8rem;
  height: 4.8rem;
  display: inline-block;
  vertical-align: top;
  transition: all 0.3s ease-in-out;
}
.photo img {
  border-radius: 100%;
  transition: all 0.3s ease-in-out;
  width: 4.8rem;
  height: 4.8rem;
}
.description {
  padding-left: 1.6rem;
  padding-top: 0;
  
  width: calc(100% - 4.8rem);
  display: inline-block;
  position: relative;
  vertical-align: middle;
  transition: all 0.3s ease-in-out;
}
.closeIcon {
  position: absolute;
  right: 0;
  cursor: pointer;
  display: none;
}
.name {
  font-size: 2rem;
  font-weight: 600;
  display: block;
}
.bio {
  margin-top: 0;
  font-size: 1.4rem;
  line-height: 2rem;
  display: block;
  height: 0px;
  opacity: 0;
  transition: all 0.1s ease-in;
}
.badge {
  margin-top: 0;
  display: block;
  height: 0px;
  opacity: 0;
  transition: all 0.1s ease-in;
}
/* Active member */
.member.active {
  padding: 0;
  box-shadow: 0 10px 20px -10px rgba(70, 70, 130, 0.5);
  grid-column-end: span 2;
  grid-row-end: span 3;
  position:relative;
}
.member.active:hover {
  box-shadow: 0 10px 20px -10px rgba(70, 70, 130, 0.5);
  top: 0;
}
.member.active .photo {
  width: 19.8rem;
  height: inherit;
  height: 100%;
  position: absolute;
}
.member.active .photo img {
  border-radius: 8px 0 0 8px;
  object-fit: cover;
  width:auto;
  height:inherit;
}
.member.active .description {
  width: calc(100% - 21.4rem);
  margin-left: 19.8rem;
  padding-top: 2.4rem;
  padding-bottom:4rem;
}
.member.active .closeIcon {
  display: block;
}
.member.active .bio {
  display: block;
  opacity: 1;
  margin-top: 1.6rem;
  height: auto;
  transition: all 0.6s ease-in;
}
.member.active .badge {
  opacity: 1;
  margin-top: 1.6rem;
  height: auto;
  transition: all 0.6s ease-in;
}
@media screen and (max-width: 800px) {
  .equipo {
    padding: 2.4rem 0;
  }
  .container {
    padding: 0 2.4rem;
  }
  .equipoListado {
    grid-template-columns: repeat(4, 25%);
    grid-gap: 0rem;
  }
  .member {
    margin: 5%;
    padding: 0.8rem;
  }
  .photo {
    width: 100%;
    height: auto;
    text-align: center;
  }
  .description {
    padding-top: 0.4rem;
    width: calc(100% - 3.2rem);
    display: none;
  }
  .name {
    font-size: 1.6rem;
  }

  /* Active member */
  .member.active {
    padding: 0.8rem;
    grid-column-end: span 4;
    grid-row-end: span 1;
    margin: 1%;
  }
  .member.active .photo {
    width: 5.6rem;
    height: 5.6rem;
  }
  .member.active .photo img {
    width: 5.6rem;
    height: 5.6rem;
    border-radius: 100%;
    position: relative;
    clip-path: none;
    margin-left: 0;
  }
  .member.active .description {
    width: calc(100% - 6.4rem);
    padding: 0.8rem 0rem 1.6rem 2.4rem;
    display: inline-block;
    margin-left: 4.8rem;
  }
  .member.active .closeIcon {
    display: block;
    right: 0;
  }
  .member.active .bio {
    display: block;
    margin-top: 0.8rem;
    height: auto;
  }
}
